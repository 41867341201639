@import "../node_modules/bootstrap/scss/bootstrap";

body { color:#000000}

// h1 { font-size:52px; }
// h2 { font-size:42px; }
// h3 { font-size:32px; }
// h4 { font-size:25px; }
// h5 { font-size:20px; }
// h6 { font-size:18px; }

.main-page {
    height: 100vh;
    overflow: hidden;
}



.logo {
    width: 20px;
    height: 32px;
    margin-right: 10px;
    float: left;
    padding: 0px;
}

.bld {
    font-weight: bold;
}

.primary-color {
    color:#f1a400;
}

.secondary-color {
    color: #7964ff;
}

.carousel-label {
    padding: 10px;
    background-color: #ebd45933;
    color: #f1a400;
    font-weight: bold;
}

.carousel-label-wrapper {
    top: 50%;
    bottom: auto;
    background-color: #ffffffcc;
    padding: 0px;
}

.icon-wrapper {
    color: #f1a400;
    border-color: #f1a400;
    font-size: 26px;
    padding: 21px;
    // border-width: 1px 1px 1px 1px;
    border-radius: 50%;
    border: 1px solid #f1a400;
    background-color: transparent;
    display: inline-block;
    line-height: 1;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    text-align: center;
    margin-bottom: 10px;
}

.icon-wrapper-reasons {
    color: #7964ff;
    border-color: #7964ff;
    font-size: 26px;
    padding: 21px;
    // border-width: 1px 1px 1px 1px;
    border-radius: 50%;
    border: 1px solid #7964ff;
    background-color: transparent;
    display: inline-block;
    line-height: 1;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    text-align: center;
    margin-bottom: 10px;
}

.reasons-container {
    display: flex;
    margin-bottom: 20px;
}

.reasons-icon {
    margin-top: 10px;
    margin-right: 20px;
}

.reasons-text {
    display: inline-block;
}

img {
    margin: auto;
    display: block;
    width: 75%;
    padding: 18px;
}

.justify {
    display: flex;
    justify-content: center;
}

.contact-container {
    padding: 50px;
}

/* Start: Google Maps Responsive */
.map-responsive {
    overflow:hidden;
    padding-bottom:400px; /*Reduce este valor si el mapa fuera muy alto, por ejemplo 250px, puedes usar porcentajes, 50%*/
    position:relative;
    height:0;
}

.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
/* End: Google Maps Responsive */